export class ShowAlertModel{
    id : string;
    button1: string;
    button2: string;
    noButtons? = true;
    large?= true;
    upperCross?= true;
    message : string;
    image : string;
      constructor() {
      }
}