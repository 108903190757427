import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
import { HttpService } from '../services/custom-http/';
import { ProjectUtils, SessionObject } from '../utility';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import 'rxjs/add/observable/throw';
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
//import { GlobalPopupService } from '../components/global-popup/global-popup.service';
// import { Router } from '@angular/router';
@Injectable()

export abstract class BaseService {



      getServiceUrl(name: string): string {
            return null;
      }

      constructor(
            //   protected router: Router,
            protected http?: HttpService

      ) {
            this.xtBaseConstructor();
      }

      xtBaseConstructor() {

      }


      GetRequest(strURL: string, body: any, responseType?: string): Observable<any> {

            let tokenId = null;
            tokenId = `MTIzNDV+I342MjQyNg==`;
            if (!responseType) {
                  this.getResponseType();
            }
            return this.http.getData(strURL, tokenId, responseType)
                  .map(this.extractData)
                  .catch(this.handleError);

      }



      getDataFromAPI(strURL: string, body: any, responseType?: string, isquery?: boolean, isfullresponce?: boolean, isheaderjson?: boolean): Observable<any> {
            let tokenId = null;

            console.log('SessionObject.getUserDetails()', SessionObject.getUserDetails());


            if (!ProjectUtils.isEmpty(SessionObject.getUserDetails())) {
                  tokenId = (ProjectUtils.isEmpty(SessionObject.getUserDetails().Token)) ? '' : SessionObject.getUserDetails().Token;

            }
            if (!responseType) {
                  this.getResponseType();
            }

            //  body = this.getBasicRequiredQueryString(body);


            // if (body[0] === '&') {

            //       body = '?' + body.substring(1);
            // } else if (body[0] !== '?') {
            //       body = '?' + body;
            // }
            // strURL += body;
            console.log(isheaderjson);
            return this.http.extractPostData(strURL, body, tokenId, responseType, isheaderjson).pipe(
                  map((res) => this.extractData(res)),
                  catchError(this.handleError));

      }

      getDataFromAPIQuery(strURL: string, body: any, responseType?: string, isquery?: boolean, isfullresponce?: boolean): Observable<any> {

            let tokenId = null;
            // this.lastUrlhit = strURL;
            // const sessionObj = SessionObject.getUserDetails();
            // tokenId = sessionObj && SessionObject.getUserDetails().Token;
            //   tokenId = `MTIzNDV+I342MjQyNg==`;
            tokenId = (SessionObject.getUserDetails().Token === undefined) ? '' : SessionObject.getUserDetails().Token;
            // tokenId = `bXBzQDEyM34jfjMyMDN+I34yMDE=`;

            if (!responseType) {
                  this.getResponseType();
            }

            return this.http.extractPostData(strURL, body, tokenId, responseType)
                  .map(this.extractData)
                  .catch(this.handleError);
      }








      // uploadFile(event: any, strURL: string, nameinForm: string, otherBody?: any): Observable<any> {
      //       let tokenId = null;
      //       const sessionObj: SessionObject = ProjectUtils.getSessionObject();
      //       tokenId = sessionObj && sessionObj.userDetails.token;
      //       return this.http.uploadData(event, strURL, nameinForm, otherBody, tokenId);
      // }

      getBasicRequiredQueryString(queryString: string): string {
            const userDetails = ProjectUtils.getSessionObject().userDetails;
            queryString += '&userId=' + userDetails.userId;
            return queryString;
      }


      getResponseType(): string {
            return '';
      }


      protected extractData(res: any) {

            console.log(res);
            const body = res; // .json();
            return body || [];
      }

      protected handleError(error: any) {
            // In a real world app, we might use a remote logging infrastructure
            // We'd also dig deeper into the error to get a better message
            const errMsg = (error.message) ? error.message :
                  error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            //   console.error(errMsg); // log to console instead
            //    localStorage.clear();
            // this.router.navigate(['']);
            //  this.globalPupUp.showGlobalPopup(`<span style="color:red; font-weight: bold"> Session has been expired!</span>`);

            //    this.globalPupUp.navigateTo(['']);
            console.error(errMsg); // log to console instead
            return observableThrowError(errMsg);
      }







}


