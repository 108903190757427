import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { RootSharedModule } from '../sharedModules';
// import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect'; 

import { AgGridComponent } from './ag-grid/ag-grid.component';
 import { PaginationComponent } from './pagination/pagination.component'; 

import { TabComponent, TabsComponent, DynamicTabsDirective } from './ng-tabs';

 //import { HighChartService } from './graph';
// import { DropdownComponent } from './dropdown/dropdown.component';
import { AlertMessageService } from './alert-message/alert-message.service';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { FilterArrayPipe } from './auto-complete/auto-complete.pipe';
import { AutoCompleteService } from './auto-complete/auto-complete.service';
// import { GlobalPopupService } from './global-popup';
  import { GlobalPopupComponent } from './global-popup/global-popup.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
 
// import { DropdownService } from './dropdown/dropdown.service';
// import { GraphService } from './graph/graph.service';

import { LoaderComponent } from './loader'; 
import { CustomModalPopUpModel, CustomModalPopUpService, CustomModalPopUpComponent } from './../components/custom-modal-pop-up';
import { MonthYearComponent } from './month-year/month-year.component';
// import { CustomCkeditorComponent } from './custom-ckeditor/custom-ckeditor.component';
import { FooterComponent } from './project-compo/footer/footer.component'; 
// import { CKEditorModule } from 'ng2-ckeditor';
import { ModalComponent } from './modal';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
// import {BrowserModule} from '@angular/platform-browser';
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { BreadCrumbService } from './bread-crumb/bread-crumb.services';
import { ShowAlertComponent } from './show-alert/show-alert.component';

@NgModule({
      declarations: [
            BreadCrumbComponent,
            AgGridComponent,
             PaginationComponent,
            TabComponent, TabsComponent, DynamicTabsDirective,
            // DropdownComponent,
            AutoCompleteComponent,
            FilterArrayPipe,
            LoaderComponent, 
            CustomModalPopUpComponent,
            // CustomCkeditorComponent,
            MonthYearComponent,
            FooterComponent,
            // CircularLinesComponent,
            ModalComponent,
            CustomDatePickerComponent,
            AlertMessageComponent ,
            GlobalPopupComponent,
            ShowAlertComponent
            // PieChartComponent,
            // LineChartComponent,
            // DrillDownChartComponent,
            // ThreeDDonutComponent,
            // BarChartComponent,
            // SemiCicleDonutComponent,
            // TreeMapChartComponent,
            // Pie3dComponent
      ],

      providers: [
            BreadCrumbService,
          //   HighChartService,
            // DropdownService,
            AutoCompleteService,
            
            // GraphService,
            CustomModalPopUpService,
            AlertMessageService 
          //  GlobalPopupService,
      ],
      exports: [ 
            BreadCrumbComponent,
            AgGridComponent, 
            TabComponent, TabsComponent, DynamicTabsDirective,
            // DropdownComponent,
            AutoCompleteComponent,
            LoaderComponent, 
            CustomModalPopUpComponent,
            AlertMessageComponent,
            // CustomCkeditorComponent,
            MonthYearComponent,
            FooterComponent, 
            ModalComponent,
            CustomDatePickerComponent ,
             GlobalPopupComponent,
             ShowAlertComponent
            // PieChartComponent,
            // LineChartComponent,
            // DrillDownChartComponent,
            // ThreeDDonutComponent,
            // BarChartComponent,
            // SemiCicleDonutComponent,
            // TreeMapChartComponent,
            // Pie3dComponent
      ],
      imports: [
            RootSharedModule,
            AgGridModule,
            OwlDateTimeModule, 
            OwlNativeDateTimeModule
            // MultiselectDropdownModule,
            // CKEditorModule
      ],
      entryComponents: [TabComponent]
})

export class ComponentModule { }
