import { Component, Input,Output, OnInit , TemplateRef,EventEmitter, OnDestroy,AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { CustomModalPopUpService } from 'src/app/acore/components/custom-modal-pop-up';
import { ShowAlertModel } from './show-alert.model';

@Component({
  selector: 'app-show-alert',
  templateUrl: './show-alert.component.html',
  styleUrls: ['./show-alert.component.css']
})
export class ShowAlertComponent implements AfterViewInit, OnInit {

  @Input()
  basicSetting : ShowAlertModel;

  @Input() template: TemplateRef<any>;

  @Output() alertAfterViewInIt = new EventEmitter();

  private isShowing: boolean;

  @Input() get show(): boolean {
    return this.isShowing;
  }  


  
  @Output() Ok = new EventEmitter();
  @Output() close = new EventEmitter();
  
  set show(val: boolean) { 
    this.cmpus.showHideAlert(val, this.basicSetting.id);
    this.isShowing = val;
  }

  constructor( private cmpus: CustomModalPopUpService,
    private cdr: ChangeDetectorRef
    ) 
    {

     }

  ngOnInit() {

    this.cmpus._registerAlertModal(this.basicSetting);
  }

  ngAfterViewInit() {
    this.cmpus.showHideAlert(this.isShowing, this.basicSetting.id);

     this.alertAfterViewInIt.emit();
  }

  onButton1Clicked() {
    this.show=false;
    this.Ok.emit();

  }
  onButton2Clicked() {
    this.show=false;
    this.close.emit();
  }

}
