import { UserDetails } from '../../../pages/login/core-login/user-details.model';

export class SessionObject {
      /* **************  USER DETAILS  *************** */
    userDetails: UserDetails;
      liveYear: any;
      liveMonth: any;
      themeData: any;

 



      /* **************  USER DETAILS  *************** */

      /* **************  DASHBOARD  *************** */
      dashBoardPost: any;
      dashBoardSec: any;


      dashBoardCharts: any;

      /* **************  DASHBOARD  *************** */




      /* **************  REPORTS  *************** */
      counterReports: any;
      customReports: any;
      costReports: any;
      sourceReports: any;
      dynamicReports: any;
      issnIsbnReports: any;
      counter5Reports: any;
      /* **************  REPORTS  *************** */



      /* **************  ADMINISTRATIVE FEATURES  *************** */

      /* **************  ADMINISTRATIVE FEATURES  *************** */



      /* **************  DATA ANALYSIS TOOL  *************** */
      zeroUsageJournal: any;
      increaseInUsageJournal: any;
      /* **************  DATA ANALYSIS TOOL  *************** */




      graphData: any;


      constructor() {

            this.graphData = null;






            /* **************  USER DETAILS  *************** */
            this.userDetails = null;
            this.liveYear = null;
            this.liveMonth = null;
            /* **************  USER DETAILS  *************** */

            /* **************  DASHBOARD  *************** */
            this.dashBoardPost = null;
            this.dashBoardSec = null;
            this.dashBoardCharts = null;
            /* **************  DASHBOARD  *************** */




            /* **************  REPORTS  *************** */
            this.counterReports = null;
            this.counter5Reports = null;
            this.costReports = null;
            this.customReports = null;
            this.dynamicReports = null;
            this.sourceReports = null;
            this.issnIsbnReports = null;
            /* **************  REPORTS  *************** */



            /* **************  ADMINISTRATIVE FEATURES  *************** */

            /* **************  ADMINISTRATIVE FEATURES  *************** */



            /* **************  DATA ANALYSIS TOOL  *************** */
            this.zeroUsageJournal = null;
            this.increaseInUsageJournal = null;
            /* **************  DATA ANALYSIS TOOL  *************** */










      }


      public static setUserDetails(userDetail: UserDetails) {
            localStorage.setItem('UserDetails', JSON.stringify(userDetail));
            // sessionStorage.setItem('UserDetails', JSON.stringify(userDetail));
        }
    
        public static getUserDetails(): UserDetails {
            return JSON.parse(localStorage.getItem('UserDetails'));
            // return JSON.parse(sessionStorage.getItem('UserDetails'));
        }



}
