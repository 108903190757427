import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.css']
})
export class CustomDatePickerComponent implements OnInit {
 // inputdate:Date;

  @Input()  inputdate:Date;

  constructor() { }

  ngOnInit() {
  }

}
