import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { SessionObject, ProjectUtils, UrlConstants } from '../../utility';

@Injectable()
export class LoginGuard implements CanActivate {

      constructor(
            private router: Router
      ) {

      }

      authUser(): Promise<boolean> {
         //   const sessionOj: SessionObject = ProjectUtils.getSessionObject();
            const userDetails = SessionObject.getUserDetails();

            return new Promise((resolve, reject) => {

                  if (ProjectUtils.isEmpty(userDetails && userDetails.userId)) {
                        resolve(true);
                  } else {
                        // this.smsService.getDataFromAPI(UrlConstants.STATES,"",'json',false,false).subscribe((data)=>{
                              resolve(false);
                              this.router.navigate(['/pages']);

                        // },(e)=>{
                        //       resolve(true);
                        //       this.router.navigate(['/']);
                        // })

                  }
            });
      }

      canActivate(): Promise<boolean> {
            
            return this.authUser();
      }



}
