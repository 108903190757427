import { UserDetails } from './../../../pages/login/core-login/user-details.model';
import { SessionObject } from './session-object';
import { Utils } from '../util';

export class ProjectUtils extends Utils {

      public static GRIDWIDTH = window.innerWidth - ((1 * ProjectUtils.getScrollbarWidth()) + 30);
      public static idCount = 0;

      public static getScrollbarWidth() {
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.width = '100px';
            outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

            document.body.appendChild(outer);

            const widthNoScroll = outer.offsetWidth;
            // force scrollbars
            outer.style.overflow = 'scroll';

            // add innerdiv
            const inner = document.createElement('div');
            inner.style.width = '100%';
            outer.appendChild(inner);

            const widthWithScroll = inner.offsetWidth;

            // remove divs
            outer.parentNode.removeChild(outer);

            return widthNoScroll - widthWithScroll;
      }











      public static setSessionObject(sessionObject: SessionObject) {
            localStorage.setItem('sessionObject', JSON.stringify(sessionObject));
      }

      public static getSessionObject(): SessionObject {
            const sessionObject = <SessionObject>JSON.parse(localStorage.getItem('sessionObject'));
            return sessionObject;
      }
      public static getUserTypeSessionObject(){
            const sessionObject = <UserDetails>JSON.parse(localStorage.getItem('UserDetails'));

            
            return sessionObject.accountTypeId;
      }

      public static clearSessionObjects() {

            
            localStorage.removeItem('UserDetails');
            localStorage.removeItem('sessionObject');
      }

      public static setDashBoardPost(dashBoardSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dashBoardPost = dashBoardSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDashBoardPost() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dashBoardPost;
      }

      public static setDashBoardSec(dashBoardSecSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dashBoardSec = dashBoardSecSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDashBoardSec() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dashBoardSec;
      }

      public static setCounterReports(counterReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.counterReports = counterReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCostReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.costReports;
      }

      public static setCostReports(costReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.costReports = costReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCounterReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.counterReports;
      }

      public static setDynamicReports(dynamicReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dynamicReports = dynamicReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDynamicReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dynamicReports;
      }

      public static setCounter5Reports(counter5Model: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.counter5Reports = counter5Model;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCounter5Reports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.counter5Reports;
      }


      public static setCustomReports(customReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.customReports = customReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getCustomReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.sourceReports;
      }

      public static setSourceReports(sourceReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.sourceReports = sourceReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static setIssnIsbnReports(isnIsbnReportsSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.issnIsbnReports = isnIsbnReportsSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getSourceReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.sourceReports;
      }

      public static getIssnIsbnReports() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.issnIsbnReports;
      }


      public static setDashBoardCharts(sourceDashBoardCharts: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.dashBoardCharts = sourceDashBoardCharts;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getDashBoardCharts() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.dashBoardCharts;
      }

      public static setZeroUsageJournal(zeroUsageSearchModel: any) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.zeroUsageJournal = zeroUsageSearchModel;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getZeroUsageJournal() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject.zeroUsageJournal;
      }

      public static setGraphData(graphData) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.graphData = graphData;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getGraphData() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject && sessionObject.graphData;
      }
      public static setThemeData(themeData) {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            sessionObject.themeData = themeData;
            ProjectUtils.setSessionObject(sessionObject);
      }

      public static getThemeData() {
            const sessionObject: SessionObject = ProjectUtils.getSessionObject();
            return sessionObject && sessionObject.themeData;
      }




      public static activeInactive(value: any) {

         //   console.log(value);
            if (value.value==1) {
                  return ' <a class="active"  ><i class="fa fa-check" Style="color: green;"></i></a> ';
            }else{

                  return ' <a  class="inactive" ><i class="fa fa-check" Style="color:gray;"></i></a> ';
            
            }
             
      }
      public static ChangeStatus(value: any) {

            //   console.log(value);
            return  ' <a class="button buttonText active"   Style="color: green;">Change Status</a> ';
         }

      public static selecticon1(key: string) {
            return function (value: any) {
                  const id = value['data'][key];
                  return `<div class="custom-control custom-checkbox">
                  <input type="checkbox" ${value['data'].select ? 'checked' : ''} class="custom-control-input" id="${id}"
                  data-dismiss="modal">
                  <label style="display:block" class="custom-control-label" for="${id}"></label>
                </div>`;

            };
      }


   public static RowOption() {
  // let userdetail=ProjectUtils.getUserTypeSessionObject();
      return function (value: any) {      
            if(ProjectUtils.getUserTypeSessionObject()==2){
                  return `<div class="dropdown show">
                  <div class="cursor-pntr" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </div>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"   >

                    <a class="dropdown-item">Edit</a>

                 
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Sender</a>
 
  
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Template</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Credit</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Account Mgr</a>                    
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Gateway</a>
                  </div>
                </div>`;
            }else{
             return `<div class="dropdown show">
                  <div class="cursor-pntr" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                    <i class="fa fa-ellipsis-v text-primary"></i>
                  </div>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"   >

                    <a class="dropdown-item">Edit</a>

                 
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Sender</a>
 
  
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Template</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">Manage Credit</a>
                                  
              
                  </div>
                </div>`;
            }  
        };         
      }



      public static activeInactivependingbtn(value:any){
            if (value.value==1) {
                  return ' <a class="button buttonText active"   Style="color: green;">Active</a> ';
            }else if(value.value==2){

                  return ' <a class="button buttonText inactive"   Style="color:gray;" >Rejected</a> ';
            
            }else{
                  return ' <a class="button buttonText inactive"   Style="color:pink;" >Pending</a> ';
            

            }

      }


      public static isFirstColumn(params) {
            const displayedColumns = params.columnApi.getAllDisplayedColumns();
            const thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
          }


      public static svgCsv(value: any) {
            if ((value.value).includes('csv')) {
                  return '<img src="./assets/images/csv.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/csv_gray.svg" class="curDisabled" />';
      }

      public static svgExcel(value: any) {
            if ((value.value).includes('xls')) {
                  return '<img src="./assets/images/excel.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/excel_gray.svg" class="curDisabled" />';
      }
      public static svgZip(value: any) {
            if ((value.value).includes('zip')) {
                  return '<img src="./assets/images/zip.svg" class="curPointer" />';
            }
            return '<img src="./assets/images/zip_gray.svg" class="curDisabled" />';
      }
      public static svgText(value: any) {
            if ((value.value).includes('txt')) {
                  return '<i class="fa fa-file-text curPointer" style="font-size:20px"></i>';
            }
            return '<i class="fa fa-file-text curDisabled" style="font-size:20px" aria-hidden="true"></i>';
      }
      public static svgHtml(value: any) {
            if ((value.value).includes('html')) {
                  return '<i class="fa fa-html5 curPointer" style="font-size:20px"></i>';
            }
            return '<i class="fa fa-html5 curDisabled" style="font-size:20px" aria-hidden="true"></i>';
      }
      public static svgXml(value: any) {
            if ((value.value).includes('xml')) {
                  return '<i class="fa fa-file-code-o curPointer" style="font-size:20px"></i>';
            }
            return '<i class="fa fa-file-archive-o curDisabled" style="font-size:20px" aria-hidden="true"></i>';
      }
      // 'csv,xls,xml,html,txt'
      public static ag_SetWidth(perc: number) {

            // let retValue = (ProjectUtils.GRIDWIDTH * perc) / 100;
            // retValue = parseInt(retValue + '', 10);
            // return retValue;
            return perc;
      }

      public static limitStringNAddToolTip(val: any) {
            const noOfChars = 45;
            val = val.value;
            const str = val.substring(0, noOfChars);
            let toShow = null;
            if (val.length > noOfChars) {
                  toShow = str + '...';
            } else {
                  toShow = str;
            }
            return `<span title=">${val}">${toShow}</span>`;
      }

      public static inputField(value: number) {
            return '<input type="text" style="font-size: small;line-height: normal;" >';
      }

      public static editDiv(value: any) {
            return '<a href="javascript:;" style="font-size:16px;"><i class="fa fa-edit"></i></a>';
      }

      
      public static deletelogo(value: any) {

            //   console.log(value);
               //return ' <a class="btn-danger less-padding-left"   ><i class="fa fa-trash-o" Style="color: white;"></i></a> ';
               return '<a href="javascript:;" style="font-size:16px;"><i class="fa fa-trash-o""></i></a>';
              }


      public static statusTxt(value: any) {
            // if(value==0) {
            // return 'Not Paid';
            // }
            // else if(value==1) {
            // return 'Paid';
            //      }
            // else {
            // return 'Expired';
            //      }

if(value.value==='complete' ){
    return value.value;  
} else if (value.value==='expired'){
      return value.value; 
}
else{
     return  '<a href="javascript:;" style="font-size:16px;"><i class="fa fa-edit"></i>'+value.value+' </a>';
}

      }


      public static isPermissionGranted(value: any) {
            if (value.colDef['headerName'] === 'Permissions' && (value.value) === 'true') {
                  return '<div class="square-block-selected"></div>';
            } else {
                  return '<div class="square-block-not-available"></div>';
            }
      }

      public static ermAccess(value: any) {

            if (value.colDef['headerName'] === 'Access details for your ERM system' && (value.value) === 'true') {
                  return '<img src="./assets/images/accessDetails.svg" class="curPointer accessDetails" />';
            } else if (value.colDef['headerName'] === 'Access details for your ERM system' && (value.value) === 'false') {
                  return '<img src="./assets/images/accessDetails-reject.svg" class="curDisabled accessDetails" />';
            }
      }

      public static selecticonredio(name: string) {
            return function (value: any) {
                  const id = 'radio-' + (++ProjectUtils.idCount);
                  return `<div class="custom-control custom-radio">
            <input type="radio" id="${id}" name="${name}" class="custom-control-input">
            <label style="display:block" class="custom-control-label" for="${id}"></label>
          </div>`;
            };
      }



      public static selecticonredio_edit(name: string) {

            return function (value: any) {
                  const id = 'radio-' + (++ProjectUtils.idCount);
                  return `<div class="custom-control custom-radio">
            <input type="radio" id="${id}" name="${name}" class="custom-control-input">
            <i class="fa fa-edit" style="font-size:16px;"></i>
            
          </div>`;
            };
      }
      public static numberValueParser(params) {
            return Number(params.newValue);
      }

      public static ag_selectedDiv(value: any) {
            if (value.colDef['headerName'] === 'Selected Platforms' && value.value !== '') {
                  return '<div class="square-block-selected"></div>';
            }
            return '';
      }

      public static ag_ColorDiv(header: any) {

            const temp = '<div class="square-block-selected"></div>';
            if (header.colDef['headerName'] === 'JR1' && (header.value).includes('JR1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR1a' && (header.value).includes('JR1a')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR2' && (header.value).includes('JR2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR3' && (header.value).includes('JR3')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'JR5' && (header.value).includes('JR5')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DB1' && (header.value).includes('DB1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'DB2' && (header.value).includes('DB2')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'PR1' && (header.value).includes('PR1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'BR1' && (header.value).includes('BR1')) {
                  return temp;
            } else if (header.colDef['headerName'] === 'BR2' && (header.value).includes('BR2')) {
                  return temp;
            } else {
                  return '<div class="square-block-not-available"></div>';
            }
      }

      public static filterDataFromDataKey(data: any) {
            if (data['data']) {
                  return data['data'];
            } else {
                  return data;
            }
      }





}
